import React, { useState, useEffect, useContext } from "react"
import { Drawer, Menu } from "antd"
import {
  UserOutlined,
  LogoutOutlined,
  ScheduleOutlined,
  EditOutlined,
  AreaChartOutlined,
  FileSearchOutlined,
  FundProjectionScreenOutlined,
  SearchOutlined,
  IdcardOutlined,
} from "@ant-design/icons"
import { FormattedMessage, useIntl } from "react-intl"
import { Link } from "gatsby"
import { logout } from "@services/auth"
import { ContextStore } from "@context"
import ChangePasswordModal from "@components/ChangePasswordModal"
import { getPermission } from "@services/auth"
import "./Main_drawer.less"

const DrawerMenu = ({ profile }) => {
  const [permission, setPermission] = useState({
    search: false,
    roleSearch: false,
    frontline: false,
    admin: false,
    report: false,
    role: false,
  })
  const [panelUrl, setPanelUrl] = useState(false)
  const { locale, changeLanguage } = useContext(ContextStore)
  const [visible, setVisible] = useState(false)

  const changePermission = res => {
    let adminPermission = res.filter(
      item => !["Search", "RoleSearch", "Report", "Frontline","Role"].includes(item)
    )
    setPermission({
      search: res.includes("Search"),
      roleSearch: res.includes("RoleSearch"),
      frontline: res.includes("Frontline"),
      admin: adminPermission.length > 0,
      report: res.includes("Report"),
      role: res.includes("Role"),
    })

    if (res.includes("AppraisalTemplate")) {
      setPanelUrl("template")
    } else if (res.includes("AppraisalCycleTable")) {
      setPanelUrl("appraisalCycle")
    } else if (res.includes("User") || res.includes("SiteAdmin")) {
      setPanelUrl("users")
    } else if (res.includes("Maintenance")) {
      setPanelUrl("siteAdmin")
    }

    return null
  }

  const editPermission = async () => {
    try {
      let res = await getPermission()
      let response = Array.isArray(res) ? [...res] : [res]
      if (res !== null) {
        changePermission(response)
      }
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    editPermission()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleClick = ({ key }) => {
    if (key === "1") {
      setVisible(true)
    }
    if (key === "2") {
      logout()
    }
    if (key === "zh" || key === "en") {
      changeLanguage(key)
    }
  }

  return (
    <div>
      <Menu onClick={handleClick}>
        <Menu.Item icon={<EditOutlined />} key="myTaskTile">
          <Link to="/">
            <FormattedMessage id="myTaskTitle" />
          </Link>
        </Menu.Item>

        <Menu.Item icon={<ScheduleOutlined />} key="mySubmissionTitle">
          <Link to="/my_submission/">
            <FormattedMessage id="mySubmissionTitle" />
          </Link>
        </Menu.Item>

        {permission.frontline && (
          <Menu.Item key="Frontline" icon={<IdcardOutlined />}>
            <Link to="/frontline/">
              <FormattedMessage id="frontline" />
            </Link>
          </Menu.Item>
        )}

        {permission.roleSearch && (
          <Menu.Item key="roleSearch" icon={<SearchOutlined />}>
            <Link to="/role_search/">
              <FormattedMessage id="search" />
            </Link>
          </Menu.Item>
        )}

        {permission.search && (
          <Menu.Item icon={<FileSearchOutlined />} key="adminSearch">
            <Link to="/search/">
              <FormattedMessage id="adminSearch" />
            </Link>
          </Menu.Item>
        )}

        {permission.report && (
          <Menu.Item icon={<FundProjectionScreenOutlined />} key="report">
            <Link to="/reports/">
              <FormattedMessage id="reportTitle" />
            </Link>
          </Menu.Item>
        )}

        {permission.admin && (
          <Menu.Item icon={<AreaChartOutlined />} key="adminPanel">
            <Link to={"/admin_panel/" + panelUrl}>
              <FormattedMessage id="adminPanel" />
            </Link>
          </Menu.Item>
        )}
        <Menu.Divider key="divider1" />

        <Menu.Item icon={<UserOutlined />} key="1">
          <FormattedMessage id="changePW" />
        </Menu.Item>
        <Menu.Item icon={<LogoutOutlined />} key="2">
          <FormattedMessage id="logout" />
        </Menu.Item>

        <Menu.Divider key="divider2" />
        {locale.includes("zh") ? (
          <Menu.Item key="en">English</Menu.Item>
        ) : (
          <Menu.Item key="zh">中文</Menu.Item>
        )}
      </Menu>
      <ChangePasswordModal
        visible={visible}
        onCancel={() => setVisible(false)}
        staffNo={profile["sub"]}
      />
    </div>
  )
}

const MainDrawer = ({ visible, profile, onClose }) => {
  const helloText = useIntl().formatMessage({ id: "hello" })
  return (
    <Drawer
      title={profile && helloText + profile["Full Name"]}
      placement="right"
      closable={false}
      onClose={onClose}
      visible={visible}
    >
      <DrawerMenu profile={profile} />
    </Drawer>
  )
}

export default MainDrawer
