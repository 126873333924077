import React, { useState } from "react"
import { Typography, Modal, Form, Input, message } from "antd"
import { UsersClient } from "@api"
import { FormattedMessage } from "react-intl"
import { errorMessage } from "@action"

const ChangePassowrdModal = ({ visible, onCancel, staffNo }) => {
  const [form] = Form.useForm()
  const [isLoading, setIsLoading] = useState(false)
  const { Paragraph } = Typography
  const [isPasswordValid, setIsPasswordValid] = useState(true)
  const onFinish = async values => {
    setIsLoading(true)
    let client = new UsersClient()
    let res
    try {
      res = await client.changePassword({
        ...values,
        userName: staffNo,
      })
      if (res.isSucceeded) {
        message.success("Change Password Success 成功更改密碼")
        onCancel()
      }
      if (res.errors.length > 0) throw res.errors
    } catch (err) {
      form.setFields([{"name":'oldPassword', errors:['Incorrect Password 密碼不正確']}])
      // errorMessage("Change Password", err)
    } finally {
      setIsLoading(false)
    }
  }
  return (
    <Modal
      forceRender
      centered
      confirmLoading={isLoading}
      visible={visible}
      okText={<FormattedMessage id="change" />}
      cancelText={<FormattedMessage id="cancel" />}
      onCancel={onCancel}
      destroyOnClose={true}
      onOk={() => form.submit()}
      title="Change Password 更改密碼"
    >
      <Paragraph style={{ color: "#958655" }}>
        Password must contain 8 characters including at least one uppercase
        character, one lowercase character and one number.
      </Paragraph>
      <Paragraph style={{ color: "#958655" }}>
        密碼必須包含八個字元，且包含至少一個大寫字母，至少一個小寫字母和至少一個數字
      </Paragraph>
      <Form
        form={form}
        onFinish={onFinish}
        layout="vertical"
        preserve={false}
        validateTrigger="onSubmit"
      >
        <Form.Item
          name="oldPassword"
          label="Current Password 目前密碼"
          rules={[
            {
              required: true,
              message: "Plesae enter Current Password. 請輸入目前密碼",
            },
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          name="newPassword"
          label="New Password 新密碼"
          rules={[
            {
              required: true,
              message: "Plesae enter New Password. 請輸入新密碼",
            },
            {
              pattern: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/,
              message:
                "Password must contain 8 characters including at least one uppercase character, one lowercase character and one number. 密碼必須包含八個字元，且包含至少一個大寫字母，至少一個小寫字母和至少一個數字",
            },
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          dependencies={["password"]}
          name="confirmPw"
          label="Confirm Password  確認密碼"
          rules={[
            {
              required: true,
              message: "Plesae enter Current Password. 請輸入目前密碼",
            },

            ({ getFieldValue }) => ({
              validator(rule, value) {
                if (!value || getFieldValue("newPassword") === value) {
                  return Promise.resolve()
                }

                return Promise.reject(
                  "The new password and confirmation password do not match. 新密碼與確認密碼不符"
                )
              },
            }),
          ]}
        >
          <Input.Password />
        </Form.Item>
      </Form>
    </Modal>
  )
}
export default ChangePassowrdModal
