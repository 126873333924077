import React, { useState, useEffect } from "react"
import { Layout, Menu, Button } from "antd"
import { Link, navigate } from "gatsby"
import {
  ScheduleOutlined,
  RightOutlined,
  LeftOutlined,
  EditOutlined,
  AreaChartOutlined,
  FileSearchOutlined,
  SearchOutlined,
  FundProjectionScreenOutlined,
  IdcardOutlined,
} from "@ant-design/icons"
import logo from "@images/ks_logo.png"
import { getPermission } from "@services/auth"
import { FormattedMessage } from "react-intl"

const MainSider = () => {
  const { Sider } = Layout
  const [isCollapsed, setIsCollapsed] = useState(true)
  const handleButtonClick = () => {
    setIsCollapsed(!isCollapsed)
  }

  const [permission, setPermission] = useState({
    search: false,
    roleSearch: false,
    frontline: false,
    admin: false,
    report: false,
  })

  const [panelUrl, setPanelUrl] = useState(false)

  const changePermission = res => {
    let adminPermission = res.filter(
      item => !["Search", "RoleSearch", "Report", "Frontline"].includes(item)
    )

    setPermission({
      search: res.includes("Search"),
      admin: adminPermission.length > 0,
      report: res.includes("Report"),
      roleSearch: res.includes("RoleSearch"),
      frontline: res.includes("Frontline"),
    })

    if (res.includes("AppraisalTemplate")) {
      setPanelUrl("template")
    } else if (res.includes("AppraisalCycleTable")) {
      setPanelUrl("appraisalCycle")
    } else if (res.includes("User") || res.includes("SiteAdmin")) {
      setPanelUrl("users")
    } else if (res.includes("Maintenance")) {
      setPanelUrl("siteAdmin")
    }

    return null
  }

  const editPermission = async () => {
    try {
      let res = await getPermission()
      let response = Array.isArray(res) ? [...res] : [res]
      if (res !== null) {
        changePermission(response)
      }
    } catch (err) {
      console.log(err)
    }
  }

  const handleClick = ({ key }) => {
    switch (key) {
      case "myTask":
        navigate("/")
        break
      case "mySubmission":
        navigate("/my_submission/")
        break
      case "roleSearch":
        navigate("/role_search/")
        break
      case "adminSearch":
        navigate("/search/")
        break
      case "reports":
        navigate("/reports/")
        break
      case "frontline":
        navigate("/frontline/")
        break
      case "adminPanel":
        navigate(`/admin_panel/${panelUrl}`)
        break
      default:
        break
    }
  }

  useEffect(() => {
    editPermission()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Sider
      theme="light"
      collapsible
      collapsed={isCollapsed}
      collapsedWidth={80}
      width={190}
      trigger={null}
    >
      <Button
        style={{
          color: "#958655",
          border: "1px solid #958655",
          position: "absolute",
          right: "-20px",
          top: " 15px",
          zIndex: "99",
        }}
        shape="circle"
        icon={isCollapsed ? <RightOutlined /> : <LeftOutlined />}
        onClick={() => handleButtonClick()}
      />
      <Link to="/">
        {isCollapsed ? (
          <img
            src={logo}
            alt="logo"
            style={{ margin: "10px auto", display: "block", maxWidth: 60 }}
          />
        ) : (
          <div
            style={{
              justifyContent: "center",
              display: "flex",
              alignItems: "center",
            }}
          >
            <img
              src={logo}
              alt="logo"
              style={{ margin: "10px 1px", maxWidth: 40 }}
            />
            <span
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                lineHeight: 1.2,
                fontSize: "14px",
              }}
            >
              Kai Shing
              <br />
              e-Appraisal System
            </span>
          </div>
        )}
      </Link>
      <Menu mode="vertical" selectedKeys={[]} onClick={handleClick}>
        <Menu.Item key="myTask" icon={<EditOutlined />}>
          <FormattedMessage id="myTaskTitle" />
        </Menu.Item>

        <Menu.Item key="mySubmission" icon={<ScheduleOutlined />}>
          <FormattedMessage id="mySubmissionTitle" />
        </Menu.Item>

        {permission.frontline && (
          <Menu.Item key="frontline" icon={<IdcardOutlined />}>
            <FormattedMessage id="frontline" />
          </Menu.Item>
        )}

        {permission.roleSearch && (
          <Menu.Item key="roleSearch" icon={<SearchOutlined />}>
            <FormattedMessage id="search" />
          </Menu.Item>
        )}

        {permission.search && (
          <Menu.Item key="adminSearch" icon={<FileSearchOutlined />}>
            <FormattedMessage id="adminSearch" />
          </Menu.Item>
        )}

        {permission.report && (
          <Menu.Item key="reports" icon={<FundProjectionScreenOutlined />}>
            <FormattedMessage id="reportTitle" />
          </Menu.Item>
        )}

        {permission.admin && (
          <Menu.Item key="adminPanel" icon={<AreaChartOutlined />}>
            <FormattedMessage id="adminPanel" />
          </Menu.Item>
        )}
      </Menu>
    </Sider>
  )
}

export default MainSider
