import React, { useState, useContext } from "react"
import { Layout, Menu, Button, Typography, Dropdown, Space, Grid } from "antd"
import {
  UserOutlined,
  DownOutlined,
  LogoutOutlined,
  MenuFoldOutlined,
} from "@ant-design/icons"
import "./main_header.less"
import { getUser, logout } from "@services/auth"
import { ContextStore } from "@context"
import { FormattedMessage } from "react-intl"
import MainDrawer from "../main_drawer/main_drawer.js"
import ChangePasswordModal from "@components/ChangePasswordModal"

const siteTitle = <FormattedMessage id="siteTitle" />

const MainHeader = ({ title = siteTitle }) => {
  const { Header } = Layout
  const { Title } = Typography
  const user = getUser()
  const profile = user ? JSON.parse(user).profile : {}
  const [visible, setVisible] = useState(false)
  const { locale, changeLanguage } = useContext(ContextStore)
  const [drawerVisible, setDrawerVisible] = useState(false)
  const { xl } = Grid.useBreakpoint()

  const LangMenu = () => {
    const handleClick = ({ key }) => {
      changeLanguage(key)
    }

    return (
      <Menu onClick={handleClick}>
        <Menu.Item key="zh">中文</Menu.Item>
        <Menu.Divider />
        <Menu.Item key="en">English</Menu.Item>
      </Menu>
    )
  }

  const UserMenu = () => {
    const handleClick = ({ _, key }) => {
      if (key === "1") {
        setVisible(true)
      }
      if (key === "3") {
        logout()
      }
    }
    return (
      <Menu onClick={handleClick}>
        <Menu.Item icon={<UserOutlined />} key="1">
          <FormattedMessage id="changePW" />
        </Menu.Item>
        <Menu.Divider />

        <Menu.Item icon={<LogoutOutlined />} key="3">
          <FormattedMessage id="logout" />
        </Menu.Item>
      </Menu>
    )
  }

  return (
    <Header>
      <Title className="title" type="secondary" level={3}>
        {title}
      </Title>

      {xl ? (
        <Space>
          <Dropdown
            arrow
            overlay={LangMenu}
            trigger={["click"]}
            placement="bottomRight"
          >
            <Button ghost>
              {locale.includes("zh") ? "中文" : "Eng"} <DownOutlined />
            </Button>
          </Dropdown>
          <Dropdown
            arrow
            overlay={UserMenu}
            trigger={["click"]}
            placement="bottomRight"
          >
            <Button ghost>
              {profile && profile["Full Name"]}
              <DownOutlined />
            </Button>
          </Dropdown>
        </Space>
      ) : (
        <Button
          ghost
          size="big"
          icon={<MenuFoldOutlined />}
          onClick={() => {
            setDrawerVisible(true)
          }}
        />
      )}

      {!xl && (
        <MainDrawer
          profile={profile}
          visible={drawerVisible}
          onClose={() => {
            setDrawerVisible(false)
          }}
        />
      )}
      {profile && (
        <ChangePasswordModal
          visible={visible}
          onCancel={() => setVisible(false)}
          staffNo={profile["sub"]}
        />
      )}
    </Header>
  )
}

export default MainHeader
