import React from "react"
import PropTypes from "prop-types"
import { Layout, Grid, Space } from "antd"
import "./main_layout.less"
import MainSider from "./main_sider/main_sider"
import MainHeader from "./main_header/main_header"

const MainLayout = ({ children, title, isForm, subHeader }) => {
  const { Content, Footer } = Layout

  const { xl } = Grid.useBreakpoint()

  return (
    <Layout>
      {xl && <MainSider />}

      <Layout>
        <MainHeader title={title} />
        {isForm ? (
          <Content className="sub-header">
            <Space
              className="space"
              align="center"
              direction="vertical"
              size="small"
            >
              {subHeader}
            </Space>
          </Content>
        ) : null}
        <Content className="main-content">
          <Space
            className="space"
            align="center"
            direction="vertical"
            size="large"
          >
            {children}
          </Space>
        </Content>
        <Footer align="center">
          © {new Date().getFullYear()},{" "}
          <a href="https://www.kaishing.hk/">
            Kai Shing Management Services Ltd.
          </a>
          All rights reserved.
        </Footer>
      </Layout>
    </Layout>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default MainLayout
